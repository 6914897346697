import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import {
  useCreateDetokenMaskMutation,
  useCreateTokenizeMutation,
  useGetMaskPolicyNameMutation,
  useGetPolicyNameMutation,
} from "../../store/feature/service/tokenization";
import detokenMask from "../../utils/validation/detokenMask";

const DetokenMask = () => {
  // REGION: INIT VARIABLES
  const location = useLocation();
  const { pathname } = location;
  const [optKey, setOptKey] = useState([]);
  const [optKeyMask, setOptKeyMask] = useState([]);
  const editFgroup = pathname.includes("edit");

  const [getPolicy, { isSuccess, isError, data, error }] =
    useGetPolicyNameMutation();
  const [
    getPolicyMask,
    {
      isSuccess: successMask,
      isError: isErrorMask,
      data: dataMask,
      error: errorMask,
    },
  ] = useGetMaskPolicyNameMutation();
  // createDetokenMask
  const [
    createDetokenMask,
    {
      isSuccess: successDetokenMask,
      isError: errorDetokenMask,
      data: dataDetokenMask,
      error: dataErrorDetokenMask,
      isLoading: loadingDetokenMask,
    },
  ] = useCreateDetokenMaskMutation();

  // const { data: dataMaskPolicy } = useGetMaskPolicyDetailQuery(idParam);
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    policyName: string;
    policyMaskName: string;
    plaintext: string;
    result: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(detokenMask),
    defaultValues: {
      policyName: "",
      policyMaskName: "",
      plaintext: "",
      result: "",
    },
  });

  // REGION: SET FORM DATA
  useEffect(() => {
    if (data) {
      setOptKey(
        data?.token_policies?.map((item: any) => ({
          label: item.name,
          value: item.name,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (dataMask) {
      setOptKeyMask(
        dataMask?.mask_policies?.map((item: any) => ({
          label: item.name,
          value: item.name,
        }))
      );
    }
  }, [dataMask]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    getPolicy({ pagesize: 100 });
    getPolicyMask({ pagesize: 100 })
  }, []);

  useEffect(() => {
    if (dataDetokenMask) {
      setValue("result", dataDetokenMask?.value);
    }
  }, [dataDetokenMask]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempVar = {
      policyName: `${e.policyName},${e.policyMaskName} `,
      tokenData: e.plaintext,
    };
    console.log("tempVar ===> ", e);
    //TO DO be continue here
    createDetokenMask(tempVar);
  };

  useEffect(() => {
    if (errorDetokenMask) {
      const dataError: any = dataErrorDetokenMask;
      // console.log('wkwkwk ===> ', dataErrorSign?.data)
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: dataError?.data?.error,
      });
    }
  }, [errorDetokenMask]);
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={loadingDetokenMask}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Detoken Mask
        </Typography>
        <SelectFormm
          name="policyName"
          label="Policy Name for Detokenize"
          defaultValue={0}
          options={optKey}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="policyMaskName"
          label="Policy Name for Masking"
          defaultValue={0}
          options={optKeyMask}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="plaintext"
          label="Tokenized Data"
          placeholder="Type Tokenized Data"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="contained" type="submit">
            Detoken Mask
          </Button>
        </Stack>

        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Result
        </Typography>
        <InputForm
          name="result"
          placeholder="Type Input Value"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
        />
      </Box>
    </DetailDataSkeleton>
  );
};

export default DetokenMask;
